import Smooth from './smooth'

class ProcessSmooth extends Smooth {

	constructor(opt) {
		super(opt)
		this.animateNetwork = opt.network
		this.mod = 0
	}

	init(){
		super.init()
	}

	run() {
		super.run()
		const mod = this.pos.current * 0.04
		this.animateNetwork(mod)
	}

	destroy() {
		super.destroy()
		//this.animateNetwork = null
	}

}

module.exports = ProcessSmooth
