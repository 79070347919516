const config = {

	BASE: '/',
	body: $('body'),
	width: window.innerWidth,
	height: window.innerHeight,
	isMobile: false

}

export default config
