import config from 'config'
import gsap from 'gsap'
import $ from 'jquery'

import BezierEasing from 'bezier-easing'
import render from '../components/render'
import stage from '../components/stage'
import loader from '../components/loader'
import Smooth from '../components/smooth'

class Home  {

	constructor(slug) {
		this.slug = this.handleize(slug == 'home' ? slug : 'home-' + slug)
		this.planets = $('.planets__svg')
	}

	pageIn(section){
		this.init(section)
	}

	goIntro(slug){

		const introname = this.handleize(slug)

		$('#planets').attr('class', '').addClass(introname)
		this.startintro = introname == 'home' ? 0 : parseInt(introname.replace('intro-', ''))
		this.slug = introname == 'home' ? introname : 'home-' + introname
		const intro = $(`[data-section="${this.slug}"]`)

		config.body.toggleClass('has-terra', this.startintro == 3)

		const tl = new TimelineMax()
		intro.css({display: 'flex'})
		TweenMax.set(intro, {x : 0, opacity:0, force3D: true})
		tl.to(this.page, 1, {x:'100%',opacity:1, force3D: true, clearProps: 'all'})
		//.to(this.planets, 3, {scaleX: 1.83, scaleY: 1.83, ease: Expo.easeOut, force3D: true})
		.to(intro, 1, {alpha:1})

		this.page = intro

	}

	pageIs(page){
		if(page == 'home')
		 return true
		else
			return false
	}

	init(slug) {
		const ready = this.ready.bind(this)
		const introname = this.handleize(slug)
		this.startintro = introname == 'home' ? 0 : parseInt(introname.replace('intro-', ''))
		config.body.toggleClass('has-terra', this.startintro == 3)
		config.body.removeClass('has-page').addClass('has-intro')
		$('#planets').addClass(introname)
		this.slug = introname == 'home' ? introname : 'home-' + introname

		loader.loadpage(this.slug).then(
			this.success.bind(this),
			this.failure.bind(this)
		)
	}

	success(response){
		this.page = response
		this.ready()
	}
	failure(error){
		//console.log(this)
	}

	ready() {
		this.smooth = new Smooth({
			section: $('.page-home')[0],
			listener: $('.scroll-container')[0]
		});

		this.animateIn()

	}

	animateIn() {
		//this.page.css({display: 'flex'})
		TweenMax.set(this.page, {x : 0, force3D: true})
		TweenMax.to(this.page, 1, {opacity: 1, force3D: true, onComplete: this.startPage, onCompleteScope: this})
	}

	startPage() {
		config.body.addClass('is-loaded')
		this.smooth.init();
		//this.resizeQue()
		//this.renders()
	}


	// resizeQue(e){
	//
	// 	if(!e)
	// 		e = stage
	//
	// }
	//
	// renders(e) {
	//
	//
	// }

	pageOut(done){
			TweenMax.killTweensOf(this.page)
		config.body.removeClass('has-nav')
		$('.hamburger').removeClass('is-active')
		console.log(this.slug, 'page out');
		this.smooth && this.smooth.destroy()
		config.body.removeClass('has-terra')
		TweenMax.set($('.content'),{clearProps: 'all'})
			if(this.page){
		TweenMax.to(this.page, .1, {opacity: 0, ease: Power4.easeOut, force3D: true})
		TweenMax.to(this.page, 1, {y: 0, ease: Power4.easeOut, onComplete: this.animateOut, onCompleteScope: this, onCompleteParams: [done],force3D: true})
	}
	else{
		this.animateOut(done)
	}
	}

	animateOut(done) {

		done()
		this.page = null
		this.destroy()
	}


	destroy() {

		this.smooth = null
		// stage.removeListener("stage:scroll", this.renderQueue)
		// stage.removeListener("stage:resize", this.resizeQueue)

	}

	handleize(str) {
    return str.toLowerCase().replace(/[^\w\u00C0-\u024f]+/g, "-").replace(/^-+|-+$/g, "");
	};
}

module.exports = Home
