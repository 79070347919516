import Smooth from './smooth'

class AboutSmooth extends Smooth {

	constructor(opt) {
		super(opt)
		this.animatePage = opt.network
		this.mod = 0
	}

	init(){
		super.init()
	}

	run() {
		super.run()
		const mod = this.pos.current 
		this.animatePage(mod)
	}

	destroy() {
		super.destroy()
		//this.animateNetwork = null
	}

}

module.exports = AboutSmooth
