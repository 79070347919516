import config from 'config'
import cache from 'cache'
import crossroads from 'crossroads'
import Homepage from './sections/home'
import Page from './sections/page'
import Process from './sections/process'
import Contact from './sections/contact'
import Team from './sections/team'
import Projects from './sections/projects'
import About from './sections/about'

class Router {

	constructor() {
		this.currentPage = null
		this.prevPage = null
		this.init()
	}

	init() {

		const home = crossroads.addRoute('/')
		const intro = crossroads.addRoute('/intro/{intro}/')
		const process = crossroads.addRoute('/process')
		const contact = crossroads.addRoute('/contact')
		const team = crossroads.addRoute('/leading-team')
		const projects = crossroads.addRoute('/projects')
		const testimonials = crossroads.addRoute('/testimonials')
		const about = crossroads.addRoute('/980ms2')
		const page = crossroads.addRoute('/{page}')


		home.matched.add(this.introRoute, this)
		intro.matched.add(this.introRoute, this)
		process.matched.add(this.processRoute, this)
		contact.matched.add(this.contactRoute, this)
		team.matched.add(this.teamRoute, this)
		projects.matched.add(this.projectsRoute, this)
		testimonials.matched.add(this.pageRoute, this)
		about.matched.add(this.aboutRoute, this)
		page.matched.add(this.pageRoute, this)

	}

	parseRoute(route){
		this.section = route == '' ? 'home' : route

		console.log(this.section);

		const href = route == '' ? '/' : route
		$('.menu__col a, .contact__col a').removeClass('is-active')
		$(`.menu__col a[href~="${href}"] , .contact__col a[href~="${href}"]`).addClass('is-active')

		crossroads.parse(route)
	}

	introRoute(intro){

		if(!cache['homepage'] ){
	    cache['homepage'] = new Homepage(this.section)
	  }


		this.prevPage = this.currentPage
	  this.currentPage = cache['homepage']

		if(this.prevPage && this.prevPage.pageIs('home')){
			this.prevPage.goIntro(this.section)
		}
		else {
			this.prevPage && this.prevPage.pageOut(this.transition.bind(this))
			!this.prevPage && this.currentPage.pageIn(this.section)
		}


	}


	pageRoute(page){

		if(!cache[this.section] ){
	    cache[this.section] = new Page(this.section)
	  }
		this.gotoRoute()
	}



	contactRoute(page){

		if(!cache[this.section] ){
	    cache[this.section] = new Contact(this.section)
	  }
		config.body.addClass('has-contact')
		this.gotoRoute()

	}

	teamRoute(page){

		if(!cache[this.section] ){
	    cache[this.section] = new Team(this.section)
	  }

		this.gotoRoute()

	}

	projectsRoute(page){

		if(!cache[this.section] ){
	    cache[this.section] = new Projects(this.section)
	  }

		this.gotoRoute()

	}

	processRoute(page){

		if(!cache[this.section] ){
	    cache[this.section] = new Process(this.section)
	  }

		this.gotoRoute()

	}

	aboutRoute(page){

		if(!cache[this.section] ){
	    cache[this.section] = new About(this.section)
	  }

		this.gotoRoute()

	}

	gotoRoute(){
		config.body.removeClass('has-intro').addClass('has-page')
		$('#planets').attr('class', '')
		this.prevPage = this.currentPage
	  this.currentPage = cache[this.section]
		this.prevPage && this.prevPage.pageOut(this.transition.bind(this))
		!this.prevPage && this.currentPage.pageIn(this.section)
	}

	transition(){
		//console.log('transition');
		this.currentPage.pageIn(this.section)
	}

	stateChange(){
		var State = History.getState();
		var url = State.url
		var relativeUrl = History.getShortUrl(url).replace(/\/$/, "");
		var s = relativeUrl;
		var m = s.indexOf('?')
		var n = m != -1 ?  m : s.length;
		relativeUrl = s.substring(0, n);
		ga('send', 'pageview', {'page': relativeUrl });
		hj('stateChange', relativeUrl);
	  this.parseRoute(relativeUrl)

	}
}

module.exports = Router
