import config from 'config'
import gsap from 'gsap'
import domCreateElement from 'dom-create-element'
import VirtualScroll from 'virtual-scroll'

class Smooth {

	constructor() {

		var opt = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
		this.isMobile = config.isMobile;
		this.direction = opt.direction || 'vertical';

		this.createBound();
		this.isMobile = false;
		this.direction = 'vertical';
		this.initialized = false

		this.vs = new VirtualScroll({
				//limitInertia: true,
				mouseMultiplier: 1,
				touchMultiplier: 1.8,
				firefoxMultiplier: 30,
			  preventTouch: true
		});

		this.pos = {
				current: 0,
				lastCurrent: 0,
				target: 0,
				height: config.height
		};

		this.dom = {
				listener: opt.listener || config.body[0],
				section: opt.section,
				stars: $('#stars')
		};

		this.scrollbar = {
				el: domCreateElement({ selector: 'div', styles: 'vs-scrollbar vs-' + this.direction }),
				drag: {
						el: domCreateElement({ selector: 'div', styles: 'vs-scrolldrag' }),
						delta: 0,
						height: 50
				}
		};

		this.draggable = {
				clicked: false,
				x: 0
		};

		this.bounding = 0;
		this.ease = opt.ease || 0.99;


	}

  createBound() {
			var _this = this;
			['calc', 'resize', 'mouseUp', 'mouseDown', 'mouseMove', 'calcScroll'].forEach(function (fn) {
					return _this[fn] = _this[fn].bind(_this);
			});
	}


	init(){
		this.resize();
		this.addEvents();
		this.addFakeScrollBar();
		this.initialized = true
	}


	calc(e) {
		var delta = this.direction == 'horizontal' ? e.deltaX : e.deltaY;
		this.pos.target += delta * -1;
		this.pos.target = this.clamp(0, this.pos.target, this.bounding);
	}

	run() {

			this.pos.current += (this.pos.target - this.pos.current) * this.ease;
			this.pos.current < .1 && (this.pos.current = 0);
			//window.delta = this.pos.current * 0.1;
			const star = this.pos.current * 0.51
			TweenMax.set(this.dom.section, {y: -Math.abs(this.pos.current.toFixed(2)), force3D: true})
			TweenMax.set(this.dom.stars, {y: -Math.abs(star.toFixed(2)), force3D: true})
			//scamera.position.set(0,500 + star ,500 + star);
			//controls.zoomIn(.95);
			//controls.rotateRight(.0002);


			var size = this.scrollbar.drag.height;
			var bounds = this.direction == 'vertical' ? config.height : config.width;
			var value = Math.abs(this.pos.current) / (this.bounding / (bounds - size)) + size / .5 - size;
			var clamp = this.clamp(0, value - size, value + size);

			TweenMax.set(this.scrollbar.drag.el, {y: clamp , force3D: true})
	}

	set(){

		TweenMax.set(this.dom.section, {y: -Math.abs(this.pos.target.toFixed(2)), force3D: true})

		var size = this.scrollbar.drag.height;
		var bounds = this.direction == 'vertical' ? config.height : config.width;
		var value = Math.abs(this.pos.target) / (this.bounding / (bounds - size)) + size / .5 - size;
		var clamp = this.clamp(0, value - size, value + size);

		TweenMax.set(this.scrollbar.drag.el, {y: clamp , force3D: true})
	}


	addFakeScrollBar() {

			var prop = this.direction == 'vertical' ? 'height' : 'width';
			this.scrollbar.drag.height = this.pos.height * (this.pos.height / (this.bounding + this.pos.height));
			$(this.scrollbar.drag.el).css(prop, this.scrollbar.drag.height);
			this.dom.listener.appendChild(this.scrollbar.el);
			this.scrollbar.el.appendChild(this.scrollbar.drag.el);

			$(this.scrollbar.el).on('click', this.calcScroll);
			$(this.scrollbar.el).on('mousedown', this.mouseDown);

			$(document).on('mousemove', this.mouseMove);
			$(document).on('mouseup', this.mouseUp);
	}

	removeFakeScrollBar() {

			$(this.scrollbar.el).off('click', this.calcScroll);
		  $(this.scrollbar.el).off('mousedown', this.mouseDown);
			$(document).off('mousemove', this.mouseMove);
			$(document).off('mouseup', this.mouseUp);
			$(this.scrollbar.el).css('display', 'none');
			this.dom.listener.removeChild(this.scrollbar.el);
	}

	calcScroll(e) {

			var client = this.direction == 'vertical' ? e.clientY : e.clientX;
			var bounds = this.direction == 'vertical' ? config.height : config.width;
			var delta = client * (this.bounding / bounds);
			config.body.addClass('is-dragging')
			this.pos.target = delta;
			this.pos.target = this.clamp(0, this.pos.target, this.bounding);
			this.scrollbar && (this.scrollbar.drag.delta = this.pos.target);


	}
	mouseDown(e) {
		//	console.log('mouseDown');
			e.which == 1 && (this.draggable.clicked = true);
	}
	mouseUp(e) {

			this.draggable.clicked = false;
			config.body.removeClass('is-dragging')
	}
	mouseMove(e) {

			this.draggable.clicked && this.calcScroll(e);
	}

	on() {
			this.vs.on(this.calc);
			TweenLite.ticker.addEventListener('tick', this.run, this, false, 1);
	}

	off() {

			this.vs.off(this.calc);
			TweenLite.ticker.removeEventListener('tick', this.run);

	}

	addEvents() {

			$(window).on('resize', this.resize);
			this.on();
	}

	removeEvents() {

		$(window).on('resize', this.resize);
		this.off();
	}

	clamp (min, value, max) {
	    return Math.max(min, Math.min(value, max))
	}


	resize() {

			config.height = window.innerHeight
			this.pos.height = config.height;
			this.bounding = this.dom.section.getBoundingClientRect().height - config.height;
			this.scrollbar.drag.height = this.pos.height * (this.pos.height / (this.bounding + this.pos.height));
			$(this.scrollbar.drag.el).css('height', this.scrollbar.drag.height);
			this.pos.target = this.clamp(0, this.pos.target, this.bounding);
			//console.log(this.pos)
			this.set()

	}

	destroy() {

		if(this.initialized){
			this.removeFakeScrollBar();
			this.removeEvents();
			this.initialized = false
		}

		TweenMax.to(this.dom.stars, 1, {y: 0, force3D: true})
		//camera.position.set(0,500 ,500);
		TweenMax.set(this.dom.section, {y: 0, force3D: true})
		this.vs.destroy();

	}


}

module.exports = Smooth
