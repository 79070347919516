
import config from 'config'
import sniffer from 'sniffer'
import 'history'
import Router from './router'
import './type.js'
import 'countup.js'

class App {

  constructor(opt = {}) {
    this.init()
  }

  init() {

    config.infos = sniffer.getInfos()

    this.router = new Router()
    this.addEvents()

    // this.smooth = new Smooth({
    //   section: $('#content1')[0]
    // });
    $(".typewriter").typed({
          strings: ["Welcome to a recent discovered universe"],
          typeSpeed: 0,
          showCursor: false
      });

    $(window).on('load', ()=>{

      TweenMax.delayedCall(2, this.startProject.bind(this))
      //$('#content1').addClass('in-view');

      var path = $('#orbit1 .line1__path').get(0);
      var pathLen = path.getTotalLength();

      var path1 = $('#orbit2 .line1__path').get(0);
      var pathLen1 = path1.getTotalLength();

      var path2 = $('#orbit3 .line1__path').get(0);
      var pathLen2 = path2.getTotalLength();

      var path3 = $('#orbit4 .line1__path').get(0);
      var pathLen3 = path3.getTotalLength();


    })

  }

  startProject(){
    config.body.addClass('loaded')
    config.height = window.innerHeight


    History.Adapter.bind(window,'statechange', this.router.stateChange.bind(this.router))
    History.Adapter.trigger(window, 'statechange')
  }

  addEvents() {

    $('.page a').on('click', (e) => {
      const target = e.currentTarget
      if ($(target).hasClass('no-route') || target.hasAttribute('target') && target.getAttribute('target') == '_blank') return
      const url = target.getAttribute('href')
      e.preventDefault()
      if( url == '' || url == '#') return
      History.pushState(null, "The Gravity - Makes IT happen", url );
    })

    $('body').on('click', '.contact a', (e) => {
      const target = e.currentTarget
      if ( $(target).hasClass('no-route') || target.hasAttribute('target') && target.getAttribute('target') == '_blank') return
      const url = target.getAttribute('href')
      e.preventDefault()
      if ($(target).hasClass('is-active')) return
      if( url == '' || url == '#') return
      History.pushState(null, "The Gravity - Makes IT happen", url );
    })

    $('.hamburger').on('click', (e) => {
      config.body.toggleClass('has-nav')
      $(e.currentTarget).toggleClass('is-active')
    })

    $('#menu_content a').on('click', (e) => {
      e.preventDefault()
      const target = e.currentTarget
      if ($(target).hasClass('is-active')) return
      const url = target.getAttribute('href')
      History.pushState(null, "The Gravity - Makes IT happen", url );
    })

    $('.main-logo a').on('click', (e) => {
      e.preventDefault()
      const url = '/'
      History.pushState(null, "The Gravity - Makes IT happen", url );
    })

  }
}

module.exports = App
