import config from 'config'
import cache from 'cache'
var Promise = require('es6-promise').Promise;

class Loader {

	loadpage(url){

		const promise =  new Promise((resolve, reject) =>  {
			const location = this.handleize(url)
			//console.log(location);
			const page = $(`[data-section="${location}"]`)
			if(page.hasClass('page-with-contact')){
				page.append($('#content6'))
			}
			if(!config.siteLoaded){
				config.siteLoaded = true
				$('body').addClass('site-loaded')
			}
			resolve(page);

		})

		return promise
	}


	handleize(str) {
    return str.toLowerCase().replace(/[^\w\u00C0-\u024f]+/g, "-").replace(/^-+|-+$/g, "");
	};



}

const instance = new Loader()
module.exports = instance
