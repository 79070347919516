import config from 'config'
import gsap from 'gsap'
import render from '../components/render'
import stage from '../components/stage'
import loader from '../components/loader'
import Smooth from '../components/about-scroll'
import CountUp from 'countup.js'

class About  {

	constructor(slug) {
		this.slug = slug
		this.countoptions = {
				useEasing: true,
				useGrouping: true,
				separator: ',',
				decimal: '.',
				prefix: '',
				suffix: ''
		};
	}

	pageIn(section){
		this.slug = section
		this.init()
	}

	pageIs(page){
		if(page == 'page')
		 return true
		else
			return false
	}

	init() {
		const ready = this.ready.bind(this)


		loader.loadpage(this.slug).then(
			this.success.bind(this),
			this.failure.bind(this)
		)

	}

	success(response){
		this.page = response
		this.ready()
	}
	failure(error){
		//console.log(this)
	}

	ready() {

		this.inview = $('[data-inview]')
		this.move = $('[data-move]')
		this.count = $('[data-count]')

		this.smooth = new Smooth({
			section: $(this.page)[0],
			listener: $('.scroll-container')[0],
			network: this.scrollPage.bind(this)
		});

		this.resizeQue = this.resize.bind(this)
		stage.on("stage:resize", this.resizeQue)


		this.header = $('.header', this.page)
		this.container = $('.curved-box', this.page)
		this.animateIn()

	}

	resize(e){

		this.inview.each((index, el)=>{
			el.dataset.top = ($(el).offset().top + this.smooth.pos.current).toFixed(0)
		})

		this.count.each((index, el)=>{
			el.dataset.top = ($(el).offset().top + this.smooth.pos.current).toFixed(0)
		})


		this.move.each((index, el)=>{
			const parent = $(el).parents('[data-movecontainer]')
			parent[0].dataset.top = (parent.offset().top + this.smooth.pos.current).toFixed(0)
		})

	}

	scrollPage(pos) {

		this.inview.each((index, el)=>{
			$(el).toggleClass('in-view', el.dataset.top < pos + config.height)
		})

		this.count.each((index, el)=>{
			if( el.dataset.top < pos + config.height && !el.dataset.done ){
				const demo = new CountUp(el.id, el.dataset.start, el.dataset.end, el.dataset.decimals, 1, this.countoptions);
				demo.start();
				el.dataset.done = 1
			}
		})

		this.move.each((index, el)=>{
			const parent = $(el).parents('[data-movecontainer]')
			if(parent[0].dataset.top < pos + config.height) {
				const movement = (pos + config.height - parent[0].dataset.top) / (config.height*.75)
				const target = el.dataset.move
				$('[data-alpha]', parent).toggleClass('is-alpha', movement*target > target*.25)
				TweenMax.set(el, {x: this.clamp(0, movement*target, target)*(el.dataset.sign || 1), force3D: true})

				if($(el).data('min')){

					const mtarget = el.dataset.max


					TweenMax.set(el, {x: '-50%', width: this.clamp(0, movement*mtarget , el.dataset.max)*(el.dataset.sign || 1), force3D: true})
				}

			}
		})

	}

	animateIn() {
		this.page.css({display: 'block'})
		TweenMax.set(this.page, {x : 0, opacity:1, force3D: true})
		TweenMax.set(this.header, {opacity:0, force3D: true})
		TweenMax.set(this.container, {opacity:0, force3D: true})
		TweenMax.to(this.header, 1.5, {y: 0, opacity:1,  ease: Power4.easeOut, onComplete: this.startPage, onCompleteScope: this, force3D: true}) //rotationX: 0, transformPerspective:100,
		TweenMax.to(this.container, 1, {y: 0, opacity:1, force3D: true, delay: .7})
	}

	startPage() {
		config.body.addClass('is-loaded')
		this.resize()
		this.smooth.init();
	}

	pageOut(done){
		this.header && TweenMax.killTweensOf(this.header)
		this.container && TweenMax.killTweensOf(this.container)
		config.body.removeClass('has-nav')
		this.smooth && this.smooth.destroy()
		$('.hamburger').removeClass('is-active')
			if(this.page){
		TweenMax.to(this.page, .1, {opacity: 0, ease: Power4.easeOut, force3D: true})
		TweenMax.to(this.page, 1, {y: 0, ease: Power4.easeOut, onComplete: this.animateOut, onCompleteScope: this, onCompleteParams: [done],force3D: true})
	}
	else{
		this.animateOut(done)
	}
	}

	animateOut(done) {



		this.page.css({display: 'none'})
		this.header && TweenMax.set(this.header, {clearProps: 'all',force3D: true})
		this.container &&  TweenMax.set(this.container, {clearProps: 'all',force3D: true})
		this.page = null
		done()
		this.destroy()
	}

	clamp(min, value, max){
	    return Math.max(min, Math.min(value, max))
	}

	destroy() {

		stage.removeListener("stage:resize", this.resizeQue)
		this.smooth = null

	}
}

module.exports = About
