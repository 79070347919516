import config from 'config'
import gsap from 'gsap'
import render from '../components/render'
import stage from '../components/stage'
import loader from '../components/loader'
import Smooth from '../components/smooth'

class Page  {

	constructor(slug) {
		this.slug = slug
	}

	pageIn(section){
		this.slug = section
		this.init()
	}

	pageIs(page){
		if(page == 'page')
		 return true
		else
			return false
	}

	init() {
		const ready = this.ready.bind(this)


		loader.loadpage(this.slug).then(
			this.success.bind(this),
			this.failure.bind(this)
		)

	}

	success(response){
		this.page = response
		this.ready()
	}
	failure(error){
		//console.log(this)
	}

	ready() {

		this.smooth = new Smooth({
			section: $(this.page)[0],
			listener: $('.scroll-container')[0]
		});

		this.slider = $('.slides', this.page)

		if(this.slider.length) {

			this.but = $('.slides .btn')
			this.slides = $('.slides .slide')

			this.but.on('click', (e) => {
				e.preventDefault()
				const active = $('.slides .is-active')
				active.removeClass('is-active')
				active.next('.slide').length && active.next('.slide').addClass('is-active');
				!active.next('.slide').length && this.slides.first().addClass('is-active');

			})

		}

		this.header = $('.page__header', this.page)
		this.container = $('.page__animation', this.page)
		this.animateIn()

	}

	animateIn() {
		this.page.css({display: 'block'})

		TweenMax.set(this.page, {x : 0, opacity:1, force3D: true})
		TweenMax.set(this.header, {opacity:0, force3D: true})
		TweenMax.set(this.container, {opacity:0, force3D: true})
		TweenMax.to(this.header, 1.5, {y: 0, opacity:1,  ease: Power4.easeOut, onComplete: this.startPage, onCompleteScope: this, force3D: true}) //rotationX: 0, transformPerspective:100,
		TweenMax.to(this.container, 2, {y: 0, opacity:1, force3D: true, delay: .7})
	}

	startPage() {
		config.body.addClass('is-loaded')
		this.smooth.init();
	}

	pageOut(done){
		this.header && TweenMax.killTweensOf(this.header)
		this.container && TweenMax.killTweensOf(this.container)
		config.body.removeClass('has-nav')
		$('.hamburger').removeClass('is-active')
		this.smooth && this.smooth.destroy()
			if(this.page){
		TweenMax.to(this.page, .1, {opacity: 0, ease: Power4.easeOut, force3D: true})
		TweenMax.to(this.page, 1, {y: 0, ease: Power4.easeOut, onComplete: this.animateOut, onCompleteScope: this, onCompleteParams: [done],force3D: true})
	}
	else{
		this.animateOut(done)
	}
	}

	animateOut(done) {

		this.page.css({display: 'none'})
		this.page = null
		this.header && TweenMax.set(this.header, {clearProps: 'all',force3D: true})
		this.container &&  TweenMax.set(this.container, {clearProps: 'all',force3D: true})
		this.header = null
		this.container = null
		done()
		this.destroy()
	}

	destroy() {

		if(this.slider.length){
			this.but.off('click')
		}


		this.smooth = null

	}
}

module.exports = Page
