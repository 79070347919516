import config from 'config'
import gsap from 'gsap'
import loader from '../components/loader'
import Smooth from '../components/process-scroll'
import vis from 'vis'


class Process  {

	constructor(slug) {
		this.slug = slug
		this.netwok = null

	}

	pageIn(section){
		this.slug = section
		this.init()
	}

	pageIs(page){
		if(page == 'process')
		 return true
		else
			return false
	}

	init() {
		const ready = this.ready.bind(this)
		this.mod = 0

		loader.loadpage(this.slug).then(
			this.success.bind(this),
			this.failure.bind(this)
		)

	}

	success(response){
		this.page = response
		this.ready()
	}
	failure(error){
		//console.log(this)
	}

	ready() {

		this.smooth = new Smooth({
			section: $(this.page)[0],
			listener: $('.scroll-container')[0],
			network: this.animateNetwork.bind(this)
		});

		this.header = $('.page__header', this.page)
		this.container = $('.page__animation', this.page)

		this.animateIn()

	}

	animateIn() {
		this.page.css({display: 'block'})
		TweenMax.set(this.page, {x : 0, opacity:1, force3D: true})
		TweenMax.set(this.header, {opacity:0, force3D: true})
		TweenMax.set(this.container, {opacity:0, force3D: true})
		TweenMax.to(this.header, 1.5, {y: 0, opacity:1,  ease: Power4.easeOut, onComplete: this.startPage, onCompleteScope: this, force3D: true}) //rotationX: 0, transformPerspective:100,
		TweenMax.to(this.container, 2, {y: 0, opacity:1, force3D: true, delay: .7})
	}

	startPage() {
		config.body.addClass('is-loaded')
		!this.network && this.createNetwork()
		this.smooth.init();

	}

	createNetwork() {
	    // create an array with nodes
	    var nodes = new vis.DataSet([
	        {id: 1, label: '   ANALYSIS  ', x: -230, y: 0, physics: false, fixed: true},
	        {id: 2, label: '   CONSULTING   ', x: 100, y: 100, physics: false, fixed: true},
	        {id: 3, label: '   RECOMMENDATIONS   ', x: 0, y: 200, fixed: true},
	        {id: 4, label: '   DEFINING THE PROJECT   ', x: 170, y: 300, fixed: true},
	        {id: 5, label: '   SETTING UP   ', x: -200, y: 370, fixed: true},
	        {id: 6, label: '   TO WORK   ', x: 170, y: 435, fixed: true},
	        {id: 7, label: '   TESTING   ', x: 0, y: 510, fixed: true},
	        {id: 8, label: '   DELIVERY   ', x: 0, y: 580, physics: false, fixed: true}
	    ]);

	    // create an array with edges
	    var edges = new vis.DataSet([
	        {from: 1, to: 2},
	        {from: 1, to: 3, dashes: true},
	        {from: 1, to: 6, dashes: true},
	        {from: 2, to: 3},
	        {from: 2, to: 5, dashes: true},
	        {from: 3, to: 4},
	        {from: 4, to: 5},
	        {from: 4, to: 7, dashes: true},
	        {from: 5, to: 6},
	        {from: 5, to: 8, dashes: true},
	        {from: 6, to: 7},
	        {from: 7, to: 8}
	    ]);

	    // create a network
	    var container = document.getElementById('mynetwork');
	    var data = {
	        nodes: nodes,
	        edges: edges
	    };
	    var options = {
	        "edges": {
	            "smooth": {
	                "type": "continuous",
	                "forceDirection": "none",
	                "roundness": 0
	            },
	            selectionWidth: 0.5
	        },
	        "physics": {
	            "enabled": false,
	        },
	        "nodes": {
	            shape: 'box',
	            shapeProperties: {
	                borderRadius: 0
	            },
	            color: {
	                border: '#ffffff',
	                background: '#32335D',
	                highlight: {
	                    border: '#ffffff',
	                    background: '#32335D'
	                },
	                hover: {
	                    border: '#ffffff',
	                    background: '#32335D'
	                }
	            },
	            labelHighlightBold: false,
	            font: {
	                color: '#ffffff',
	                size: 30,
	                face: 'Steelfish'
	            }
	        },
	        "interaction": {
	            dragView: false,
	            zoomView: false
	        }
	    };
	    this.network = new vis.Network(container, data, options);
			container.className = 'loaded'
	}

	animateNetwork(end) {

			if(Math.abs(this.mod - end) > .1){
			this.mod = end

			const mod = this.mod
	    if (this.network) {
	        this.network.setData({
	            nodes: new vis.DataSet([
	                {id: 1, label: '   ANALYSIS   ', x: -230, y: 0, physics: false, fixed: true},
	                {id: 2, label: '   CONSULTING   ', x: 100 + mod, y: 100, physics: false, fixed: true},
	                {id: 3, label: '   RECOMMENDATIONS   ', x: 0 + mod, y: 200, fixed: true},
	                {id: 4, label: '   DEFINING THE PROJECT   ', x: 170 - mod, y: 300, fixed: true},
	                {id: 5, label: '   SETTING UP   ', x: -200 - mod, y: 380, fixed: true},
	                {id: 6, label: '   TO WORK   ', x: 170 + mod, y: 435, fixed: true},
	                {id: 7, label: '   TESTING   ', x: 0 + mod, y: 510, fixed: true},
	                {id: 8, label: '   DELIVERY   ', x: 0, y: 580, physics: false, fixed: true}
	            ]), edges: new vis.DataSet([
	                {from: 1, to: 2},
	                {from: 1, to: 3, dashes: true},
	                {from: 1, to: 6, dashes: true},
	                {from: 2, to: 3},
	                {from: 2, to: 5, dashes: true},
	                {from: 3, to: 4},
	                {from: 4, to: 5},
	                {from: 4, to: 7, dashes: true},
	                {from: 5, to: 6},
	                {from: 5, to: 8, dashes: true},
	                {from: 6, to: 7},
	                {from: 7, to: 8}
	            ])
	        });
	        this.network.redraw();
	    }
		}
	}

	pageOut(done){
		this.header && TweenMax.killTweensOf(this.header)
		this.container && TweenMax.killTweensOf(this.container)
		config.body.removeClass('has-nav')
		$('.hamburger').removeClass('is-active')
		this.smooth && this.smooth.destroy();
			if(this.page){
		TweenMax.to(this.page, .1, {opacity: 0, ease: Power4.easeOut, force3D: true})
		TweenMax.to(this.page, 1, {y: 0, ease: Power4.easeOut, onComplete: this.animateOut, onCompleteScope: this, onCompleteParams: [done],force3D: true})
	}
	else{
		this.animateOut(done)
	}
	}

	animateOut(done) {

		this.page.css({display: 'none'})
		this.page = null
		this.header && TweenMax.set(this.header, {clearProps: 'all',force3D: true})
		this.container &&  TweenMax.set(this.container, {clearProps: 'all',force3D: true})
		this.header = null
		this.container = null
		done()
		this.destroy()
	}


	destroy() {

		this.smooth = null
	}
}

module.exports = Process
