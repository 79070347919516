import config from 'config'
import gsap from 'gsap'
import render from '../components/render'
import stage from '../components/stage'
import loader from '../components/loader'
import Smooth from '../components/smooth'

class Contact  {

	constructor(slug) {
		this.slug = slug
	}

	pageIn(section){
		this.slug = section
		this.init()
	}

	pageIs(page){
		if(page == 'contact')
		 return true
		else
			return false
	}

	init() {
		const ready = this.ready.bind(this)

		loader.loadpage(this.slug).then(
			this.success.bind(this),
			this.failure.bind(this)
		)

	}

	success(response){
		this.page = response
		this.ready()
	}
	failure(error){
		//console.log(this)
	}

	ready() {

		this.smooth = new Smooth({
			section: $(this.page)[0],
			listener: $('.scroll-container')[0]
		});

		this.animateIn()

	}

	animateIn() {
		this.page.css({display: 'block'})
		TweenMax.set(this.page, {x : 0, opacity:0, force3D: true})
		TweenMax.to(this.page, 2, { opacity:1, onComplete: this.startPage, onCompleteScope: this, force3D: true})

	}

	startPage() {
		config.body.addClass('is-loaded')
		this.smooth.init();
	}

	pageOut(done){
		TweenMax.killTweensOf(this.page)
		config.body.removeClass('has-nav')
		config.body.removeClass('has-contact')
		$('.hamburger').removeClass('is-active')


		this.smooth && this.smooth.destroy()

		if(this.page){
		TweenMax.to(this.page, .1, {opacity: 0, ease: Power4.easeOut, force3D: true})
		TweenMax.to(this.page, 1, {y: 0, ease: Power4.easeOut, onComplete: this.animateOut, onCompleteScope: this, onCompleteParams: [done],force3D: true})
		}
		else{
			this.animateOut(done)
		}
	}

	animateOut(done) {

		this.page.css({display: 'none'})
		this.page = null
		done()
		this.destroy()
	}

	destroy() {


		this.smooth = null

	}
}

module.exports = Contact
